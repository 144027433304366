import Form from "app/components/organisms/form";
import React from "react";

const defaultTypes = [
  { label: "Experience", value: "EXPERIENCE" },
  { label: "Story", value: "STORY" },
];

export default function SelectElement(props) {
  const { value, types = defaultTypes, onChange } = props;

  const elementType = value?.type?.value || undefined;

  const structure = [
    { type: "Select", key: "type", title: "Type", options: types },
    { type: "Select", key: "element", title: "Element", elements: true, clearable: true, elementType },
  ];

  return (
    <div className="input-select-flex">
      <Form structure={structure} value={value} onChange={onChange} />
    </div>
  );
}
