import { i18n } from "app/i18n";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import { Content, Footer, Header, Struture } from "../../modal";
import GeocoderControl from "./geocoder";

export default function InputMap(props) {
  const { value, onChange } = props;

  const viewport = { latitude: 39.4176048817897, longitude: -8.158639285968963, zoom: 6 };

  const [isOpen, setIsOpen] = useState(false);
  const [marker, setMarker] = useState(null);

  const API_TOKEN = process.env.REACT_APP_MAP_ACCESS_TOKEN;

  useEffect(() => {
    if (value?.latitude && value?.longitude) setMarker({ ...value });
  }, [value]);

  async function reverseGeocode({ longitude, latitude }) {
    // const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${API_TOKEN}&language=pt`;
    // const YOUR_API_KEY = `71ac2c667c194fb4aaab41a282dbd106`;
    // const url = `https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&format=json&apiKey=${YOUR_API_KEY}`;
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Falha na requisição de geocodificação");

      const data = await response.json();

      let place = null,
        city = null,
        district = null;

      if (data.address) {
        district = data?.address?.county || null;
        city = data?.address?.town || data?.address?.city || data?.address?.municipality || null;
        place = data?.address?.village || data?.address?.neighbourhood || city || null;
      }

      return { place, city, district };
    } catch (error) {
      console.error("Erro ao obter dados de geocodificação:", error);
      return { place: null, city: null, district: null };
    }
  }

  const handleMapClick = (event) => {
    setMarker({ longitude: event.lngLat.lng, latitude: event.lngLat.lat });
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  const onClickSubmit = () => {
    if (marker) {
      reverseGeocode(marker).then((result) => {
        if (result) onChange({ ...marker, ...result });
        else onChange({ ...marker });
      });
    }
    onClose();
  };

  return (
    <div className="input-map rounded-lg border bg-gray-100 block mb-3">
      <div className="h-36" onClick={onOpen}>
        <ReactMapGL
          zoom={value ? 12 : 6}
          mapboxAccessToken={API_TOKEN}
          latitude={value?.latitude || viewport.latitude}
          longitude={value?.longitude || viewport.longitude}
          mapStyle="mapbox://styles/mapbox/light-v9"
        >
          {value && <Marker latitude={value.latitude} longitude={value.longitude} />}
        </ReactMapGL>
      </div>

      <Struture isOpen={isOpen} onClose={onClose} size="md">
        <Header onClose={onClose} />
        <Content noPad>
          <div className="h-[800px] relative">
            <ReactMapGL width="100%" height="800px" onClick={handleMapClick} mapboxAccessToken={API_TOKEN} initialViewState={{ ...viewport }} mapStyle="mapbox://styles/mapbox/outdoors-v12">
              {marker && <Marker latitude={marker.latitude} longitude={marker.longitude} />}
              <GeocoderControl mapboxAccessToken={API_TOKEN} position="top-left" />
            </ReactMapGL>
          </div>
        </Content>
        <Footer cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: i18n("button.save"), onClick: onClickSubmit }} />
      </Struture>

      {marker && (
        <div className="text-xs px-4 py-2 border-t">
          {marker.latitude && (
            <p>
              <strong>Latitude:</strong> {marker.latitude}
            </p>
          )}
          {marker.longitude && (
            <p className="mb-2">
              <strong>Longitude:</strong> {marker.longitude}
            </p>
          )}

          {marker.district && (
            <p className="flex items-center">
              <strong>Distrito:</strong>
              <input
                type="text"
                defaultValue={marker.district}
                onChange={(ev) => {
                  const m = { ...marker, district: ev.target.value };
                  setMarker(m);
                  onChange(m);
                }}
                className="p-0 border-0 bg-transparent inline ml-1 text-xs focus:p-1 focus:bg-white"
              />
            </p>
          )}
          {marker.city && (
            <p className="flex items-center">
              <strong>Concelho:</strong>
              <input
                type="text"
                defaultValue={marker.city}
                onChange={(ev) => {
                  const m = { ...marker, city: ev.target.value };
                  setMarker(m);
                  onChange(m);
                }}
                className="p-0 border-0 bg-transparent inline ml-1 text-xs focus:p-1 focus:bg-white"
              />
            </p>
          )}
          {marker.place && (
            <p className="flex items-center">
              <strong>Freguesia:</strong>
              <input
                type="text"
                defaultValue={marker.place}
                onChange={(ev) => {
                  const m = { ...marker, place: ev.target.value };
                  setMarker(m);
                  onChange(m);
                }}
                className="p-0 border-0 w-full bg-transparent inline ml-1 text-xs focus:p-1 focus:bg-white"
              />
            </p>
          )}
        </div>
      )}
    </div>
  );
}
