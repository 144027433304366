import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/icon";
import { i18n } from "app/i18n";
import { useGetElementsCountQuery, useGetElementsQuery } from "app/stores/inputs";
import { isObject } from "app/utils/validators/dataValidator";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

export default function SelectElements(props) {
  const { type, value, label, onChange, createButton, disabled, clearable, multiple, placement, error } = props;

  const styles = {
    button:
      "relative w-full cursor-default flex items-center overflow-hidden text-sm rounded-lg bg-gray-50 text-left focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300",
    input: "w-full border-none py-2.5 pl-2.5 pr-10 leading-5 bg-gray-50 focus:ring-0 ",
    options: "absolute pb-6 w-full z-10 text-base ring-0 focus:outline-none sm:text-sm bg-transparent ",
  };
  // STATE
  const itemsPerPage = 12;
  const [query, setQuery] = useState("");
  const [take, setTake] = useState(itemsPerPage);

  const getSearchText = (ev) => {
    let search = "";
    const text = getLabel(value) || null;
    if (text) search = ev.replace(text, "");
    else search = ev;
    setQuery(search);
  };

  const getLabel = (_val) => {
    if (!_val) return "";
    if (multiple) return `${_val.map((item) => item.label).join(", ")} `;
    else if (!_val.label) return "";
    return `${_val.label} `;
  };

  const clearButton = () => {
    const isClearable = clearable;
    if (!isClearable) return null;
    if (!value || !isObject(value, true)) return null;

    return (
      <span
        type="button"
        disabled={disabled}
        className="cursor-pointer absolute inset-y-0 right-7 flex items-center pr-2"
        onClick={(ev) => {
          ev.stopPropagation();
          onChange(undefined);
        }}
      >
        <Icon name="close" className="text-gray-400" />
      </span>
    );
  };

  if (error) styles.button += " border border-red-600 focus:border-red-600 focus-visible:border-red-600";
  else styles.button += " border border-gray-300 focus:border-blue-500 focus-visible:border-blue-500";

  if (!getLabel(value)) styles.input += " text-xs text-gray-400";
  else styles.input += " text-sm text-gray-900";

  if (placement === "top") styles.options += " mb-1 bottom-full";
  else styles.options += " mt-1 top-full";

  // PARAMS
  const { language } = useSelector((store) => store.project);

  /////////////
  // OPTIONS //
  /////////////
  const skip = 0;
  const title = query
    ?.toLocaleLowerCase()
    ?.normalize("NFD")
    ?.replace(/[\u0300-\u036f]/g, "");

  const where = { type, translations: { some: { language: { slug: { equals: language.slug } }, title: title ? { contains: title, mode: "insensitive" } : undefined } } };
  const orderBy = { createdAt: "desc" };

  const { data } = useGetElementsQuery({ take, skip, where, orderBy }, { skip: !type });
  const { data: total } = useGetElementsCountQuery({ where });

  let options = data?.map(({ id, translations }) => ({ value: id, label: translations.find((e) => e.language.slug === language.slug).title || id }));

  return (
    <Combobox value={value} onChange={onChange} multiple={multiple} nullable={clearable} disabled={disabled}>
      <div className="relative">
        <div className={styles.button}>
          <Combobox.Input
            placeholder={label}
            displayValue={(value) => getLabel(value)}
            onChange={(event) => {
              getSearchText(event.target.value);
              if (query) setTake(itemsPerPage);
            }}
            className={styles.input}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            {clearButton()}
            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </Combobox.Button>
        </div>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery("")}>
          <Combobox.Options className={styles.options}>
            <div className="bg-white rounded-md py-1 shadow-lg">
              <div className="max-h-40 overflow-auto">
                {!options?.length && query !== "" ? (
                  <div className="relative cursor-pointer select-none py-2 px-4 text-gray-700">{i18n("label.nothing_found")}</div>
                ) : options?.length ? (
                  <>
                    {options.map((option, key) => (
                      <Combobox.Option
                        key={key}
                        value={option}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 pl-10 pr-4 transition-all ease-in-out duration-300 ${active ? "bg-blue-100 text-blue-900" : "text-gray-900"}`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{option.label}</span>
                            {selected ? (
                              <span className={`absolute inset-y-0 left-0 flex items-center pl-3 transition-all ease-in-out duration-300  ${active ? "text-white" : "text-blue-600"}`}>
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                    {total > options.length && (
                      <button type="button" className="btn-primary" onClick={() => setTake((p) => p + itemsPerPage)}>
                        Load more
                      </button>
                    )}
                  </>
                ) : (
                  <div className="relative cursor-pointer select-none py-2 px-4 text-gray-700">{i18n("label.nothing_found")}</div>
                )}
              </div>
              {createButton}
            </div>
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
