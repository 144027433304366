import ModalMedia from "app/components/molecules/modals/modalMedia";
import { useEffect, useState } from "react";
import Icon from "../../icons/icon";
import { getFileExtension, getFilename, getSource, isImageExtension, isVideoExtension } from "app/utils/content";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Text from "../text";
import Tooltip from "../../tooltip";
import { i18n } from "app/i18n";

export default function Multimedia(props) {
  const { value, multi = false, title, description, formats, externals, disabled, error, errorMessage, clearMode, isRequired, noAlt, noPoster, onChange } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [altActive, setAltActive] = useState(false);
  const [posterActive, setPosterActive] = useState(false);

  // VARS
  const hasAlt = value?.alt;
  const hasPoster = value?.poster?.id;
  const extension = value?.path ? getFileExtension(value.path) : null;

  // STYLES
  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
    box: "h-36 group relative aspect-video bg-gray-50 border rounded-lg overflow-hidden",
  };

  if (error) {
    styles.title += " text-red-600";
    styles.box += " border-red-600";
  } else {
    styles.title += " text-slate-700";
    styles.box += " border-gray-300";
  }

  if (disabled) styles.box += " pointer-events-none";

  // DATA VALIDATIONS
  useEffect(() => {
    if (value?.path) {
      if (posterActive && !isVideoExtension(extension)) setPosterActive(false);
      if (altActive && !isImageExtension(extension)) setAltActive(false);
    }
  }, [extension, posterActive]);

  useEffect(() => {
    if (hasAlt && !altActive) setAltActive(true);
    if (hasPoster && !posterActive) setPosterActive(true);
  }, [hasPoster, hasAlt, posterActive]);

  // FUNCTIONS
  const openModalPoster = () => {
    setModalOpen({ isOpen: true, formats: ["IMAGE"], onSubmit: onSubmitPoster });
  };
  const openModal = () => {
    let allFormats = formats?.length ? [...formats] : [];
    if (externals) {
      if (allFormats.includes("VIDEO")) allFormats.push("EXTERNAL_VIDEO");
      if (allFormats.includes("IMAGE")) allFormats.push("EXTERNAL_IMAGE");
      if (allFormats.includes("FILE")) allFormats.push("EXTERNAL_FILE");
    }
    setModalOpen({ isOpen: true, value: value, formats: allFormats, onSubmit: onSubmit });
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const onSubmit = (val) => {
    if (disabled) return true;
    const newExtension = val?.path ? getFileExtension(val.path) : null;
    if (newExtension !== extension) onChange({ ...val });
    else onChange({ ...value, ...val });
    closeModal();
  };
  const onSubmitPoster = (val) => {
    if (disabled) return true;
    onChange({ ...value, poster: { ...val } });
    closeModal();
  };
  const onCleanPoster = () => {
    if (disabled) return true;
    const temp = { ...value };
    delete temp.poster;
    onChange({ ...temp });
  };

  return (
    <div className="block mb-3">
      <div className="inline-block">
        <div className="flex items-end justify-between">
          <div>
            {title && <label className={styles.title}>{title}</label>}
            {description && <span className={styles.description}>{description}</span>}
          </div>
          {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
        </div>
        <div className="flex gap-2">
          <div className="relative">
            <div className={styles.box}>
              {value?.id ? (
                <button type="button" className="flex items-center justify-center w-full h-full" onClick={!disabled ? openModal : undefined}>
                  {isImageExtension(extension) ? (
                    <img src={getSource(value.path)} alt={value.id} className="absolute inset-0 object-cover w-full h-full" />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gray-300 group-hover:opacity-75">
                      <div>
                        <span className="block text-3xl uppercase font-bold">{extension}</span>
                        <span className="block text-[10px] uppercase text-slate-400">{getFilename(value.path)}</span>
                      </div>
                    </div>
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={!disabled ? openModal : undefined}
                  className="h-full w-full flex items-center justify-center text-gray-300 font-bold hover:bg-gray-100 hover:text-gray-400 transition-all ease-in-out duration-300"
                >
                  <Icon name="plus" height="h-8" width="w-8" />
                </button>
              )}
              <div className="absolute inset-0 transition-all duration-300 ease-linear group-hover:bg-black/30" onClick={openModal} />
            </div>
            {!disabled && value?.id && (
              <>
                <div className="absolute z-[1] inset-0 p-2 flex items-end justify-between pointer-events-none">
                  <div className="flex gap-2 pointer-events-auto">
                    {/* ALTERNATIVE TEXT */}
                    {!noAlt && isImageExtension(extension) && (
                      <Tooltip message={i18n("label.set_alternative_text")} placement="top">
                        <button
                          type="button"
                          disabled={value?.alt}
                          onClick={() => setAltActive(!altActive)}
                          className={`custom-transition inline-block my-0.5  py-0.5 px-2 text-xs cursor-pointer rounded-md ${
                            altActive ? `bg-slate-800 text-white` : `bg-white text-black hover:bg-slate-300`
                          }`}
                        >
                          ALT
                        </button>
                      </Tooltip>
                    )}

                    {/* POSTER */}
                    {!noPoster && isVideoExtension(extension) && (
                      <Tooltip message={i18n("label.set_poster_video")} placement="top">
                        <button
                          type="button"
                          disabled={value?.poster}
                          onClick={() => setPosterActive(!posterActive)}
                          className={`custom-transition inline-block my-0.5  py-0.5 px-2 text-xs cursor-pointer rounded-md ${
                            posterActive ? `bg-slate-800 text-white` : `bg-white text-black hover:bg-slate-300`
                          }`}
                        >
                          POSTER
                        </button>
                      </Tooltip>
                    )}
                  </div>
                  <div className="flex gap-2 pointer-events-auto">
                    {/* CHANGE */}
                    <button type="button" className="btn-icon text-white hover:text-slate-700" onClick={openModal}>
                      <Icon name="edit" />
                    </button>

                    {/* DELETE */}
                    <button
                      type="button"
                      className="btn-icon text-white hover:bg-red-500"
                      onClick={() => {
                        if (clearMode) onChange({ disconnect: true });
                        else onChange(null);
                        if (altActive) setAltActive(false);
                        if (posterActive) setPosterActive(false);
                      }}
                    >
                      <Icon name="delete" />
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* POSTER */}
          {posterActive && (
            <div className={styles.box}>
              <button type="button" onClick={openModalPoster} className="flex items-center justify-center w-full h-full">
                <div className="w-full h-full flex items-center justify-center bg-gray-300 group-hover:opacity-75">
                  {value?.poster?.path && isImageExtension(getFileExtension(value.poster.path)) ? (
                    <img src={getSource(value.poster.path)} alt={value.poster.id} className="absolute inset-0 object-cover w-full h-full" />
                  ) : (
                    <div>
                      <span className="block text-2xl text-slate-600 uppercase font-bold">POSTER</span>
                      <span className="block text-[10px] uppercase text-slate-400">Cover image while video loading</span>
                    </div>
                  )}
                </div>
              </button>
              <button type="button" onClick={onCleanPoster} className="absolute top-2 right-2">
                <Icon name="close" />
              </button>
            </div>
          )}
        </div>
        {/* POSTER */}
        {!noAlt && altActive && (
          <div className="mt-1 w-full">
            <Text value={value?.alt} onChange={(ev) => onChange({ ...value, alt: ev })} placeholder="Alternative Text" />
          </div>
        )}
        {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
      </div>

      <ModalMedia {...modalOpen} multi={multi} onClose={closeModal} />
    </div>
  );
}
