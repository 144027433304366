import { WrenchIcon } from "@heroicons/react/24/outline";
import ModalToken from "app/components/molecules/modals/modalToken";
import { i18n } from "app/i18n";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function Rebuild() {
  // PARAMS
  const { lang } = useParams();

  // STORE
  const { post } = useSelector((state) => state.post);
  const { project } = useSelector((state) => state.project);

  // STATES
  const [modal, setModal] = useState(null);

  // VARS
  const content = post?.translations?.find((item) => item.language.slug === lang);

  const onClick = () => {
    setModal({
      isOpen: true,
      type: "rebuild",
      content: content.id,
      project: project.slug,
      onClose: () => setModal(null),
    });
  };

  if (!project?.modules?.buttonRebuild) return null;
  if (project?.mode !== "PRODUCTION") return null;

  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 !outline-none transition-all duration-500 ease-in-out !ring-0"
      >
        <WrenchIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
        {i18n("button.rebuild")}
      </button>

      <ModalToken {...modal} />
    </>
  );
}
