import dayjs from "dayjs";
import DatePicker, { registerLocale } from "react-datepicker";
import Icon from "../../icons/icon";

// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";

// LOCALE
import pt from "date-fns/locale/pt";
import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";

// STORE
import { useSelector } from "react-redux";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Tooltip from "../../tooltip";
import { i18n } from "app/i18n";

export default function Date(props) {
  const { value = undefined, title, inline, showTime, dateFormat, yearSelector, monthSelector, error, errorMessage, isRequired, description, readOnly, disabled, clearable = true, onChange } = props;

  const formatDate = dateFormat?.value || "dd/MM/yyyy";
  const formatTime = "HH:mm";

  const { language } = useSelector((state) => state.project);

  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
    datepicker: "bg-gray-50 text-gray-900 border text-sm rounded-lg !outline-none block w-full p-2.5 trasition-all duration-300 ease-in-out !ring-0 !focus-visible:border-none",
    icon: "inline-flex items-center h-[42px] px-3 text-sm text-gray-400 bg-gray-200 border border-r-0 rounded-l-md",
  };

  if (error) {
    styles.title += " text-red-600";
    styles.datepicker += " border-red-600 border-l-gray-300 hover:border-red-600 hover:border-l-gray-300 focus:border-red-600 focus:border-l-gray-300";
    styles.icon += " border-red-600";
  } else {
    styles.title += " text-slate-700";
    styles.datepicker += " border-gray-300 hover:border-gray-400 focus:border-blue-700";
    styles.icon += " border-gray-300";
  }

  let locale = language?.slug;
  if (language?.slug) {
    if (language.slug === "pt") locale = pt;
    else if (language.slug === "en") locale = en;
    else if (language.slug === "fr") locale = fr;
  }

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <label className="inline-flex items-center">
        <span className={styles.icon} aria-disabled={disabled}>
          <Icon name="calendar" />
        </span>
        <DatePicker
          locale={locale}
          inline={inline}
          readOnly={readOnly}
          disabled={disabled}
          showTimeInput={showTime}
          className={styles.datepicker}
          showYearDropdown={yearSelector}
          showMonthDropdown={monthSelector}
          selected={value ? dayjs(value).toDate() : undefined}
          dateFormat={`${formatDate} ${showTime ? formatTime : ""}`}
          onChange={(date) => onChange(date ? dayjs(date).toISOString() : null)}
        />
        {clearable && !disabled && (
          <div className="ml-2">
            <Tooltip message={i18n("button.clear")}>
              <button type="button" onClick={() => onChange(null)} className="btn-icon">
                <Icon name="close" />
              </button>
            </Tooltip>
          </div>
        )}
      </label>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
