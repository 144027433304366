import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Input from "./input";

export default function Select(props) {
  const { title, searchable, loadmore, elements, error, errorMessage, isRequired, description } = props;

  let mode = "normal";

  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
  };

  if (searchable) mode = "search";
  else if (loadmore) mode = "loadmore";
  else if (elements) mode = "elements";

  if (error) styles.title += " text-red-600";
  else styles.title += " text-slate-700";

  return (
    <div className="block w-full mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <Input {...props} mode={mode} />
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
