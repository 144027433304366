import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const components = [
  {
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Select",
    key: "types",
    multiple: true,
    title: i18n("label.types"),
    options: [
      { value: "STORY", label: "Story" },
      { value: "EXPERIENCE", label: "Experience" },
      { value: "PARTNER", label: "Partner" },
      { value: "EVENT", label: "Event" },
    ],
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "SelectIcon",
    key: "icon",
    title: i18n("label.icon"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Group",
    key: "translations",
    title: i18n("label.title"),
    children: [
      {
        type: "Text",
        key: "title",
        title: i18n("label.title"),
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
    ],
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
];

export { components };
