import ElementEdition from "app/components/organisms/editor/element/edition";
import ElementHeader from "app/components/organisms/editor/element/header";
import ElementSidebar from "app/components/organisms/editor/element/sidebar";
import { i18n } from "app/i18n";
import { useGetPartnerQuery, useUpdatePartnerMutation } from "app/stores/private";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useParams } from "react-router-dom";

export default function PartnerEdit() {
  // PARAMS
  const { id, lang } = useParams();

  // QUERY
  const { data, isLoading: isGetting, refetch } = useGetPartnerQuery({ id });

  // MUTATION
  const [updateElement, { isLoading: isUpdating }] = useUpdatePartnerMutation();

  // VARS
  const isLoading = isGetting || isUpdating;
  const content = lang && data?.translations.find((e) => e.language.slug === lang);

  const onSaveElement = (content, element, revision) => {
    if (content) delete content.id;
    if (content) delete content.publishedDate;

    let values = {
      latitude: element?.coords?.latitude || data?.latitude,
      longitude: element?.coords?.longitude || data?.longitude,
      address: element?.address || data?.address,
      district: element?.coords?.district || data?.district?.name, // DISTRITO
      city: element?.coords?.city || data?.place?.city?.name, // CONCELHO
      place: element?.coords?.place || data?.place?.name, // FREGUESIA
      score: element?.score ? parseFloat(element.score) : undefined,
      email: revision?.data?.email || undefined,
      phonenumber: revision?.data?.phonenumber || undefined,
      channels: revision?.data?.channels || undefined,
      banners: revision?.data?.banners || undefined,
      image: undefined,
      content: content || undefined,
      revision: undefined,
    };

    // IMAGE
    if (element?.image) {
      // CONNECT TO FILE
      if (element?.image?.id) values.image = { connect: { id: element?.image?.id } };
      // IF DISCONNECT
      if (element?.image?.disconnect) values.image = { disconnect: true };
    }

    // CATEGORIES
    if (element?.categories) {
      values.categories = { connect: element.categories.map((e) => ({ id: e.value })) };
    }

    // REVISION
    if (revision) {
      values.revision = { data: {} };

      // STATUS
      if (revision?.status) values.revision.status = revision?.status;

      // DATA
      if (revision?.data) {
        // LEVEL
        values.revision.data.level = revision?.data?.level;
        // DESCRIPTION
        values.revision.data.description = revision?.data?.description;
        values.revision.data.description1 = revision?.data?.description1;
        // GALLERY
        values.revision.data.gallery = revision?.data?.gallery;
        // VIDEO
        values.revision.data.video = revision?.data?.video;
      }
    }

    updateElement({ id: data.id, language: lang, data: values }).then((res) => {
      if (res?.data) {
        showServerSuccess(i18n("toast.success_update"));
        refetch();
      } else {
        showServerError(res);
      }
    });
  };

  return (
    <div className="h-full flex flex-col relative">
      {/* HEADER */}
      <ElementHeader element={data} content={content} onSave={onSaveElement} />

      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="h-12 bg-blue-900" />
        <div className="-mt-12 custom-container flex-1 h-full items-start overflow-hidden flex flex-row gap-6">
          {/* EDITION */}
          <ElementEdition element={data} loading={isLoading} onSave={onSaveElement} />

          {/* SIDEBAR */}
          <ElementSidebar element={data} loading={isLoading} onSave={onSaveElement} whenTranslateSuccedded={refetch} />
        </div>
        <div className="h-6" />
      </div>
    </div>
  );
}
